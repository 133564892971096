function setActionUrl() {
  const newsletterBloc = document.querySelector('.footerNewsletter');
  if (newsletterBloc) {
    let form = document.getElementById('newsLetter__form');
    const newsletterRadio = document.querySelector('.newsLetter__radio');
    let newsletterbtn = document.getElementsByClassName('newsLetter__button')[0];

    if (newsletterbtn) {
      newsletterbtn.addEventListener('click', function(e) {
        if (newsletterRadio) {
          getRadioChecked(form);
        }

        setEmail(form);
        document.getElementById('newsLetter__form').submit();
      }, false);
    }
  }
}

function setEmail(form) {
  let email = document.getElementById('footerNewsletterEmail').value;
  let link = form.getAttribute('action');
  if (email) {
    form.setAttribute('action', link+'?email='+email);
  }
}

function getRadioChecked(form) {
  const checkedRadio = document.querySelector('input[name="site"]:checked').value;
  if (checkedRadio) {
    form.setAttribute('action', checkedRadio);
  }
}

document.addEventListener('DOMContentLoaded', setActionUrl(), false);
