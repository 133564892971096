import { getContact } from '@accor/customer-api/dist';


async function callCenter() {
  try {
    const getContactImpl = await getContact();
    const dataFromGetContact = await getContactImpl(
      {
        fields: 'loyalty.loyaltyCards.card.cardProduct.productTier',
        filter: 'loyalty.loyaltyCards.card:isLastActiveCard=true'
      }
    );
    let productTier = null;
    if (dataFromGetContact && dataFromGetContact.loyalty && dataFromGetContact.loyalty.loyaltyCards.card){
      productTier = dataFromGetContact.loyalty.loyaltyCards.card[0].cardProduct.productTier;
    }
    if ( productTier !== 'Limitless' ||  productTier === null){
      return;
    }
    let toReplace = document.getElementsByClassName('jsLimitlessReplace');
    if (toReplace.length > 0) {
      for (let i = 0; i < toReplace.length; i++) {
        let el = toReplace[i];
        if (el.dataset.limitless === '') {
          el.parentNode.removeChild(el);
        } else if (el.tagName === 'A') {
          el.outerHTML = '<p class="jsLimitlessReplace">' + el.dataset.limitless + '</p>';
        } else {
          el.innerHTML = el.dataset.limitless;
        }
      }
    }

    let titles = document.getElementsByClassName('callCenter__title');
    if (titles.length > 0) {
      for (let i = 0; i < titles.length; i++) {
        let el = titles[i];
        el.classList.add('callCenter__title--limitless');
        el.style.display = 'block';
      }
    }

    let callCenterId = document.querySelector('#call-center');
    if (callCenterId) {
      document.querySelector('#call-center').classList.add('free');
    }

    let limitlessCga = document.getElementById('limitlessCga');
    if (limitlessCga) {
      limitlessCga.setAttribute('href', limitlessCga.getAttribute('href').replace('terms-and-conditions', 'terms-and-conditions-limitless'));
    }
  } catch (e) {
    console.warn('LoginNav service error: getUser failed');
    return Promise.reject(e);
  }
}

document.addEventListener('DOMContentLoaded', callCenter(), false);
