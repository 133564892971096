function collapseItems() {
  let list = document.querySelectorAll('.footerComponent .linksList')[0];

  if (list) {
    let uls = list.getElementsByClassName('linksList__ul');
    let btns = list.getElementsByClassName('linksList__btn');

    list.addEventListener('click', function (e) {
      let _this = e.target;
      if (_this.tagName.toLowerCase() === 'button') {
        let thisUl = _this.closest('.linksList__list').querySelector('.linksList__ul');
        let flag = false;
        if (!thisUl.matches('.open')) {
          flag = true;
        }

        for (let i in uls) {
          if (uls.hasOwnProperty(i)) {
            uls[i].classList.remove('open');
          }
        }

        for (let i in btns) {
          if (btns.hasOwnProperty(i)) {
            btns[i].setAttribute('aria-expanded', false);
            btns[i].classList.remove('open');
          }
        }

        if (flag) {
          if (!thisUl.matches('.open')) {
            let _thisBtn = _this.closest('.linksList__list').querySelector('.linksList__btn');
            _thisBtn.setAttribute('aria-expanded', true);
            _thisBtn.classList.add('open');
            thisUl.style.display = 'block';
            setTimeout(function () {
              thisUl.classList.add('open');
            }, 10);
          }
        }
      }
    }, false);

    for (let i in uls) {
      if (uls.hasOwnProperty(i)) {
        uls[i].addEventListener('transitionend', function () {
          if (!this.matches('.open')) {
            this.style.display = '';
          }
        }, false);
      }
    }
  }
}

document.addEventListener('DOMContentLoaded', collapseItems(), false);
