function moveHelpBloc() {
  const newsletterBloc = document.querySelector('.footerNewsletter');
  const callCenterBloc = document.querySelector('.callCenter');
  const helpsBloc = document.querySelector('.footerLinks--help');

  if (!newsletterBloc && !callCenterBloc && helpsBloc) {
    const helpsLinks = helpsBloc.querySelector('.linksList__list');

    if (helpsLinks) {
      const footerLinks = document.querySelector('.footerLinks .linksList');

      if (footerLinks) {
        footerLinks.appendChild(helpsLinks);
        helpsBloc.remove();
      }
    }
  }
}

document.addEventListener('DOMContentLoaded', moveHelpBloc(), false);
